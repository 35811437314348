import React from "react"
import {Alert, Box, Button, CircularProgress, LinearProgress, Typography} from "@mui/material";
import {getLocalizer} from "../scripts/languageSettings";

const l = getLocalizer();
const ProgressView = (props) => {
	const MAX = 100;
	const MIN = 0;
	const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);

	if (props.error) {
		return <div style={{padding: "50px", marginTop: "50px"}}>
			<Alert variant="filled" severity="error"
			       sx={{marginBottom: "25px"}}>{l("error")}</Alert>
			<Button variant="outlined" onClick={props.tryAgain} sx={{marginRight: "5px"}}
			>{l("try_again_btn")} </Button> <Button variant="outlined" onClick={props.resetForm}
			                                        sx={{marginRight: "5px"}}
		>{l("reset_btn")} </Button>
		</div>
	}
	return <div style={{padding: "50px", marginTop: "50px"}}>
		<Box sx={{display: 'flex', alignItems: 'center'}}>
			<Box sx={{width: '100%', mr: 1}}>
				<LinearProgress
					variant="determinate" value={normalise(props?.value)}
					sx={{height: 30, borderRadius: 2}}/>
			</Box>
			<span><CircularProgress size={15}/></span><Box sx={{minWidth: 35}}><Typography variant="body2"
			                                                                               color="text.secondary">{`${Math.round(
			normalise(props?.value),
		)}%`}</Typography>
		</Box>
		</Box>
		<Typography variant="body2" color="text.primary"
		            sx={{marginTop: "25px"}}>{l("please_wait_process")}</Typography>
	</div>
}
export default ProgressView;