import React, {useEffect, useState} from "react";
import {
	Alert,
	Box,
	Button,
	CircularProgress, Dialog, DialogTitle, ListItem, ListItemText,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow, Tooltip
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import {v4 as uuidv4} from 'uuid';
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import RefreshIcon from '@mui/icons-material/Refresh';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {getLocalizer} from "../scripts/languageSettings";

const l = getLocalizer();
export const originalCols = ['Kohdetyyppi', 'Kunta', 'Kaupunginosa', 'Postinumero', 'Katuosoite', 'Asuinala', 'Rakennusvuosi', 'Huoneita', 'Huoneistoselite', 'Kerrosnumero', 'Kerrokset', 'Kauppahinta', 'Velkaosuus', 'Velaton hinta', 'Neliöhinta', 'Uudiskohde', 'Kiinteistönkunto', 'Kaupan pvm', 'Tontin omistajuus', 'Tontin ala neliöinä', 'Myynnin aloittamispäivä', 'Myyntiaika pv', 'Hoitovastike', 'Hoitovastike per neliö', 'Rakennusmateriaali', 'Ranta', 'Hissi', 'Vuokrattu', 'Sauna', 'Parveke', 'Rakennusvuoden kuvaus', 'Tontin rakennusala', 'Muu ala', 'Rakennusoikeudet', 'Rannan kuvaus', 'Lämmönlähde', 'Energialuokka']
export const checkHeaders = (originals, checkingCol) => {
	let missingOriginals = [];
	originals.forEach(item => {
		if (!checkingCol.includes(item)) {
			missingOriginals.push(item)
		}
	})
	return missingOriginals;
}

const PreviewTable = (props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [expandRows, setExpandRows] = useState(10)
	const [rows, setRows] = useState([])
	useEffect(() => {
		setRows([...props.data])
	}, [props.data])
	const getIcon = (data) => {
		if (data === "FALSE") {
			return <CloseIcon sx={{color: "red"}}/>
		}
		if (data === "TRUE") {
			return <CheckCircleIcon sx={{color: "green"}}/>
		}
		return data
	}


	const getColumnName = (param) => {
		if (param === "prediction") {
			return l("prediction")
		}
		if (param === "difference_percentage") {
			return l("difference")
		}
		return param
	}

	const getResultsCols = () => {
		return props.cols.filter(item => item === "Katuosoite" ||
			item === "Asuinala" ||
			item === "Kiinteistönkunto" ||
			item === "Velaton hinta" ||
			item === "Kauppahinta" ||
			item === "Kaupan pvm" ||
			item === "prediction" ||
			item === "difference_percentage"
		).map((item, index) => {
			return <TableCell align="right" key={uuidv4()} sx={{
				backgroundColor: "#ebebeb",
				fontWeight: 700
			}}>{getColumnName(item)}</TableCell>
		})
	}

	const getResultRows = () => {
		return rows.slice(0, expandRows).map((item, index) => {
			return <TableRow
				key={uuidv4()}
				sx={{'&:last-child td, &:last-child th': {border: 0}}}
			>
				<TableCell align="right">{index + 1}</TableCell>
				<TableCell align="right">{item["Katuosoite"] ? item["Katuosoite"] : ""}</TableCell>
				<TableCell align="right">{item["Asuinala"] ? item["Asuinala"] : ""}</TableCell>
				<TableCell align="right">{item["Kauppahinta"] ? item["Kauppahinta"] : ""}</TableCell>
				<TableCell align="right">{item["Velaton hinta"] ? item["Velaton hinta"] : ""}</TableCell>
				<TableCell align="right">{item["Kiinteistönkunto"] ? item["Kiinteistönkunto"] : ""}</TableCell>
				<TableCell align="right">{item["Kaupan pvm"] ? item["Kaupan pvm"] : ""}</TableCell>
				<TableCell align="right"
				           sx={{backgroundColor: "rgba(63, 96, 135, 0.26)"}}
				>{item["prediction"] ? item["prediction"]?.toFixed(2) : " - "}</TableCell>
				<TableCell
					sx={{backgroundColor: "rgba(89, 191, 193, 0.36)"}}
					align="right">{item["difference_percentage"] ? item["difference_percentage"] + "%" : " - "}</TableCell>
			</TableRow>
		})

	}
	if (props.error) {
		return <div style={{padding: "50px", marginTop: "50px"}}>
			<Alert variant="filled" severity="error"
			       sx={{marginBottom: "25px"}}>{props.errorMessage || "Something went wrong while uploading!"} </Alert>
			<Button variant="outlined" onClick={props.resetForm} sx={{marginRight: "5px"}}
			>Try again with new session!</Button>
		</div>
	}
	return <div style={{
		overFlow: "hidden",
		width: "100%",
		margin: "0 auto",
		marginTop: "50px",
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	}}>
		{props.type !== "result" && checkHeaders(originalCols, props.cols).length && props.data.length ?
			<div><Tooltip title={"Click to see missing headers"}>
				<Alert variant="filled" severity="error"
				       sx={{marginBottom: "25px", cursor: "Pointer", width: "100%"}}
				       onClick={() => setIsModalOpen(true)}>Missing
					headers!</Alert>
			</Tooltip></div> : null
		}
		{props.type !== "result"
			?
			<Dialog onClose={() => setIsModalOpen(false)} open={isModalOpen}>
				<DialogTitle>Missing headers</DialogTitle>

				{checkHeaders(originalCols, props.cols).map((item) => (
					<ListItem key={uuidv4()}>
						<ListItemText primary={item}/>
					</ListItem>
				))}

			</Dialog>
			: null}
		<TableContainer component={Paper}
		                sx={{maxHeight: 400, width: "100%", overflow: "scroll", minHeight: 430, padding: "25px 0px"}}>
			{!props.data.length ?
				<Box sx={{marginTop: "100px"}}><CircularProgress/>
					<p>{l("please_wait_file")}</p>
				</Box> :
				<Table sx={{minWidth: 650}} aria-label="sticky table">
					<TableHead>
						<TableRow>
							<TableCell align="right" sx={{
								backgroundColor: "#ebebeb",
								fontWeight: 700
							}}>#</TableCell>
							{props.type === "result" ? getResultsCols() : props.cols?.map((item, index) => {
								return <TableCell key={uuidv4()} sx={{
									backgroundColor: "#ebebeb",
									fontWeight: 700
								}}>{item}</TableCell>
							})}
						</TableRow>
					</TableHead>
					<TableBody>
						{props.type === "result" ? getResultRows() : rows?.slice(0, expandRows).map((row, index) => (
							<TableRow
								key={uuidv4()}
								sx={{'&:last-child td, &:last-child th': {border: 0}}}
							>
								<TableCell key={uuidv4()}
								           align="right">{index + 1}</TableCell>
								{Object.keys(row).map((key, indexTwo) => {

									return <TableCell key={uuidv4()}
									                  align="right">{row[key] ? getIcon(row[key]) : " - "}</TableCell>
								})}
							</TableRow>
						))}
					</TableBody>
				</Table>}
		</TableContainer>
		<Box sx={{display: 'flex', width: "100%", justifyContent: "flex-start", marginTop: "10px"}}>
			<Button variant="outlined" size={"small"}
			        startIcon={<KeyboardArrowDownIcon/>}
			        disabled={rows.length >= props.data}
			        onClick={() => setExpandRows(prev => prev + 100)}>{l("expand_rows")}</Button>
		</Box>
		{props.type !== "result" ?
			<div style={{display: "flex", width: "100%", justifyContent: "flex-start", marginTop: "10px"}}>
				<Box
					sx={{fontStyle: 'normal', m: 1}}>{l("total_rows")}{props?.dataLength &&
					<b>{props.dataLength}</b>}</Box>
				<Box
					sx={{
						fontStyle: 'oblique',
						opacity: 0.5,
						fontSize: "12px",
						m: 1
					}}>{props?.file && props.file.name}</Box>
			</div> : null}
		<div style={{
			width: "100%",
			display: "flex",
			justifyContent: "flex-end",
			alignItems: "center",
			marginTop: "30px"
		}}>
			<Button variant="outlined" onClick={props.resetForm}
			        startIcon={<RefreshIcon/>}
			        sx={{marginRight: "5px", color: "#113c70", borderColor: "#113c70"}}
			>{props.type === "result" ? l("reset_btn") : l("upload_new_file_btn")}</Button>
			<Button sx={{backgroundColor: "#113c70"}} variant="contained"
			        startIcon={props.type === "result" ?
				        <SimCardDownloadIcon/> : <DoubleArrowIcon/>}
			        onClick={props.type === "result" ? props.exportToXLSX : props.handleClick}
			        disabled={!props.data.length || checkHeaders(originalCols, props.cols).length ? true : false || props.isBtnLoading}>{props.type === "result" ? l("export_btn") : l("proceed_btn")}</Button>
			{props.isBtnLoading && <CircularProgress size={20} sx={{marginLeft: "5px"}}/>}
			{props.type === "result" &&
				<Button color={"success"} variant={"contained"} onClick={props.getReport}
				        startIcon={<PictureAsPdfIcon/>}
				        sx={{marginLeft: "5px"}}
				>{l("report_preview")}</Button>}


		</div>
	</div>
}
export default PreviewTable;