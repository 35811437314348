import Polyglot from 'node-polyglot';
import {englishPhrases} from './en';
import {finnishPhrases} from './fi';

let instance = new Polyglot();
let localizer = instance.t.bind(instance);

export function setLocale(locale, phrases) {
	instance.locale(locale);
	instance.replace(phrases);
}

export function getLocale() {
	return instance.locale();
}

export function getLocalizer() {
	return localizer;
}

export function setLanguage(countryCode) {
	let phrases = () => {
		switch (countryCode) {
			case "EN":
				return englishPhrases;
			default:
				return finnishPhrases;
		}
	};
	setLocale(countryCode, phrases())
}

export function checkKeyExist(key) {
	return instance.has(key);
}