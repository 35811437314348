export const finnishPhrases =  {
	"select_file": "Valitse tiedosto",
	"preview": "Esikatselu",
	"progress": "Edistyminen",
	"results": "Tulokset",
	"drag_drop": "Vedä ja pudota tiedosto tähän tai valitse tiedosto",
	"accepted_format": "Hyväksytty tiedostomuoto: ",
	"please_wait_file": "Ole ystävällinen ja odota kun tiedostoa ladataan",
	"total_rows": "Tiedostossa on rivejä yhteensä:",
	"upload_new_file_btn": "Lataa uusi tiedosto",
	"proceed_btn": "Jatka",
	"please_wait_process": "Ole ystävällinen ja odota kunnes lataus on saatu päätökseen",
	"error": "Jotain meni pieleen",
	"reset_btn": "Nollaa istunto",
	"export_btn": "Vie",
	"missing_headers": "Puuttuvat otsikot",
	"prediction": "Ennuste",
	"difference": "Ero",
	"try_again_btn": "Yritä uudelleen",
	"expand_rows": "Näytä lisää",
	"report_preview": "Raportin esikatselu",
	"incorrect_date":"Incorrect date, please try with format: 'yyyy-mm-dd'"
}