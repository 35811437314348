import React, {useCallback, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {getLocalizer} from "../scripts/languageSettings";
import {Alert, Dialog, DialogTitle, ListItem, ListItemText, Tooltip} from "@mui/material";
import {v4 as uuidv4} from "uuid";


const l = getLocalizer();
const SheetJSFT = [
	"xlsx"
].map(function (x) {
	return "." + x;
}).join(",");

const FileUploader = (props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const onDrop = useCallback(acceptedFiles => {
		props.handleChange(acceptedFiles[0])
	}, [props])

	const {getRootProps, getInputProps} = useDropzone({onDrop: onDrop, accept: SheetJSFT});
	return (
		<div style={{padding: "50px", marginTop: "50px"}}>
			{props.missingHeaders.length ?
				<>
					<div><Tooltip title={"Click to see missing headers"}>
						<Alert variant="filled" severity="error"
						       sx={{margin: '0 auto', marginBottom: "25px", cursor: "Pointer", width: "50%",}}
						       onClick={() => setIsModalOpen(true)}>Missing
							headers!</Alert>
					</Tooltip></div>
					<Dialog onClose={() => setIsModalOpen(false)} open={isModalOpen}>
						<DialogTitle>Missing headers</DialogTitle>

						{props.missingHeaders.map((item) => (
							<ListItem key={uuidv4()}>
								<ListItemText primary={item}/>
							</ListItem>
						))}
					</Dialog></> : null}

			<section className="dragableContainer">
				<div {...getRootProps({className: 'dropzone'})}>
					<input {...getInputProps()} accept={SheetJSFT}/>
					<p><b>{l("drag_drop")}</b></p>
					<p>{l("accepted_format")} {SheetJSFT}</p>
					<br/>
					<UploadFileIcon sx={{fontSize: "42px"}}/>

				</div>
			</section>
		</div>

	)

}

export default FileUploader;