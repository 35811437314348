export  const englishPhrases =  {
	"select_file":"Select file",
	"preview":"Preview",
	"progress":"Progress",
	"results":"Results",
	"drag_drop":"Drag 'n' drop file here, or click to select files",
	"accepted_format":"Accepted format: ",
	"please_wait_file":"Please wait while loading the file",
	"total_rows":"Total rows: ",
	"upload_new_file_btn":"Upload new file",
	"proceed_btn":"Proceed",
	"please_wait_process":"Please wait until the process ends",
	"error":"Something went wrong",
	"reset_btn":"Reset session",
	"export_btn":"Export",
	"missing_headers":"Missing headers",
	"prediction":"Prediction",
	"difference":"Difference",
	"try_again_btn":"Try again",
	"expand_rows" : "Expand rows",
	"report_preview" : "Report preview",
	"close":"Close",
	"incorrect_date":"Incorrect date, please try with format: 'yyyy-mm-dd'"

}