import './App.css';
import FileUploader from "./components/FileUploader";
import { Alert, Avatar, Button, Grid, Paper, Stack, Step, StepLabel, Stepper } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import PreviewTable from "./components/PreviewTable";
import XLSX from "xlsx";
import mainLogo from './images/logoSkenario.png';
import englishFlag from './images/england.webp';
import finnishFlag from './images/finland.png';
import ProgressView from "./components/ProgressView";
import axios from "axios";
// import moment from "moment";
import { getLocalizer, setLanguage } from "./scripts/languageSettings";
import ReportPDFpreview from "./components/reportPDFpreview";
import { v4 as uuidv4 } from "uuid";


const steps = ['select_file', 'preview', 'progress', 'results'];
const l = getLocalizer();
setLanguage(localStorage.getItem('language') || "FI");


function App() {
	const [SESSION_ID, setSESSION_ID] = useState(new Date().toISOString() + ":" + uuidv4());
	const [userLanguage, setUserLanguage] = useState(localStorage.getItem('language') || "FI")
	const [modelVersion, setModelVersion] = useState("");
	const [activeStep, setActiveStep] = useState(0);
	const [btnLoading, setBtnLoading] = useState(false)
	//file comp
	const [file, setFile] = useState("");
	const [dataForCSV, setDataForCSV] = useState([]); // this the full data that will go to CSV file
	const [data, setData] = useState([]); // data displayed on table view
	const [cols, setCols] = useState([])
	const [csv, setCSV] = useState("")
	//progress bar
	const [ticking, setTicking] = useState(false);
	const [progress, setProgress] = useState(10);
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("false");
	//report
	const [reportPDF, setReportPDF] = useState("")
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [modalError, setModalError] = useState(false)
	// const missing headers
	const [missingHeaders, setMissingHeaders] = useState([])
	const getModelVersionDays = () => {
		const datediff = (first, second) => {
			return Math.round((second - first) / (1000 * 60 * 60 * 24));
		}

		if (modelVersion) {
			const year = modelVersion.substring(0, 4)
			const month = modelVersion.substring(4, 6)
			//CHANGE THIS IMMEDIATLY!! AFTER   MODEL GETS UPDATED
			const day = modelVersion.substring(6, 8)
			const modelDate = `${year}-${month}-${day}`
			const dateObj = new Date(modelDate)

			return {
				modelVersion: modelDate,
				daysDifference: datediff(dateObj, new Date())
			}
		}

	}

	const getStatus = useCallback(() => {
		const getResults = () => {
			let url = 'https://model-performance-api.ml-production.skenarios.dev/results'
			axios({
				method: "GET",
				url: url,
				headers: {
					'allow': 'e=mc^2',
					'session': SESSION_ID
				},
			}).then(function (response) {
				if (response.data.length) {
					setProgress(100)
					setTicking(false)
					setCols(Object.keys(response.data[0])?.map(item => item))
					setData(response.data)
					setActiveStep(3);
					setBtnLoading(false)
				}
			})
				.catch(function (error) {
					console.log(error);
					setBtnLoading(false)
					setError(true)
				});
		}
		let url = 'https://model-performance-api.ml-production.skenarios.dev/status'
		axios({
			method: "GET",
			url: url,
			headers: {
				'allow': 'e=mc^2',
				'session': SESSION_ID
			},
		}).then(function (response) {
			if (response.data === "File ready") {
				setTicking(false);
				if (activeStep === 2) {
					getResults();
				}
			} else {
				ticking && setProgress(response.data)
			}
		})
			.catch(function (error) {
				setTicking(false);
				setError(true);
				console.log(error);

			});
	}, [activeStep, ticking, SESSION_ID])

	useEffect(() => {
		const getProgression = () => {
			if (activeStep === 2 && error !== true) {
				getStatus();
			}
		}

		const timer = setInterval(() => getProgression(), 5000)
		return () => clearInterval(timer)
	}, [activeStep, progress, error, getStatus])


	//Change of file from FileUploader
	const handleChange = (fileParam) => {
		setFile(fileParam)
	};

	const sendFile = () => {
		let url = 'https://model-performance-api.ml-production.skenarios.dev/'
		let formData = new FormData();
		formData.append("file", csv)
		axios({
			method: "POST",
			url: url,
			data: formData,
			withCredentials: false,
			headers: {
				'allow': 'e=mc^2',
				'accept': 'application/json',
				'Content-Type': 'multipart/form-data',
				'session': SESSION_ID
			},
		})
			.then(function (response) {
				setTicking(true)
				setActiveStep(2)
			})
			.catch(function (error) {
				setError(true)
				setBtnLoading(false)
				if (error.response && error.response.data && error.response.data.message) {
					let message;
					if (error.response.status === 418) {
						message = l("incorrect_date")
					}
					setErrorMessage(message)
				}
				console.log(error);
			});
	}


	const handleFile = useCallback(() => {
		/* Boilerplate to set up FileReader */
		const reader = new FileReader();
		reader.onload = (e) => {
			/* Parse data */
			const bstr = e?.target?.result;
			const wb = XLSX.read(bstr, {type: 'binary'});
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			/* Convert array of arrays */
			//makes to JSON
			const dataFile = XLSX.utils.sheet_to_json(ws, {raw: false, defval: null,});
			//makes to CSV
			//Gets columns
			const columns = dataFile[0];

			// if (checkHeaders(originalCols, Object.keys(columns)?.map(item => item)).length) {
			// 	setMissingHeaders(checkHeaders(originalCols, Object.keys(columns)?.map(item => item)))
			// 	setActiveStep(0)
			// 	return
			// }
			dataFile.forEach(item => {
				delete item["__EMPTY"]
				delete item["Unnamed: 0"]
			})
			setCols(Object.keys(columns)?.map(item => item))
			//Data that will be used in CSV
			setDataForCSV(dataFile)
			//Creates Data for preview
			setData(dataFile)

		};
		reader.readAsBinaryString(file);
		setActiveStep(1)

	}, [file])

	// const isItemLastTwelveMonths = (dateToCheck) => {
	// 	if (dateToCheck.includes("/")) {
	// 		let formatDate = dateToCheck.split("/");
	// 		let itemDate = new Date("20" + formatDate[2], formatDate[0] - 1, formatDate[1]); //format from excel is MM/DD/YY
	// 		let today = new Date();
	// 		let twelveMonthsAgo = moment(new Date()).subtract(1, 'year');
	// 		let isLastTwelveMonths = itemDate >= twelveMonthsAgo && itemDate <= today
	// 		return isLastTwelveMonths;
	// 	} else {
	//
	// 		let formatDate = dateToCheck.split("-");
	// 		let itemDate = new Date(formatDate[0], formatDate[1], formatDate[2]);
	// 		let today = new Date();
	// 		let twelveMonthsAgo = moment(new Date()).subtract(1, 'year');
	// 		let isLastTwelveMonths = itemDate >= twelveMonthsAgo && itemDate <= today
	// 		return isLastTwelveMonths;
	// 	}
	//
	// }


	useEffect(() => {
		// const getRandomRows = (items) => {
		// 	let selectedRows = [];
		// 	let amountToTake = Math.round(items.length / 10);
		// 	let i = 0;
		// 	while (i < amountToTake) {
		// 		selectedRows.push(items[Math.floor(Math.random() * items.length)]);
		// 		i++;
		// 	}
		// 	//filters last 12 months items
		// 	selectedRows.filter(item => isItemLastTwelveMonths(item["Kaupan pvm"]))
		// 	//filters dublicate values
		// 	selectedRows.filter((item, index, self) =>
		// 			index === self.findIndex((t) => (
		// 				t["Katuosoite"] === item["Katuosoite"] && t["Kaupunginosa"] === item["Kaupunginosa"]
		// 			))
		// 	)
		// 	return selectedRows;
		// }
		// let dataToCSVandAPI = dataForCSV.length > 100000 ? getRandomRows(dataForCSV) : dataForCSV.filter(item => isItemLastTwelveMonths(item["Kaupan pvm"])); //CHANGE LATER??
		let dataToCSVandAPI = dataForCSV; //CHANGE LATER??
		dataToCSVandAPI.forEach(item => {
			delete item["__EMPTY"]
			delete item["Unnamed: 0"]
		})
		let dataToSheet = XLSX.utils.json_to_sheet(dataToCSVandAPI);
		const dataFileCSV = XLSX.utils.sheet_to_csv(dataToSheet, {raw: false, defval: null});
		let blob = new Blob(["\ufeff", dataFileCSV]);
		//change this name
		let fileBlob = new File([blob], "name");
		setCSV(fileBlob)
	}, [dataForCSV])


	// DO NOT DELETE , this might need if we need to send the files as chunks.
	// const createChunk = () => {
	// 	const chunkSize = 1048576 * 0.1; // 3MB
	// 	let numberofChunks = Math.ceil(csv.size / chunkSize);
	// 	let start = 0;
	// 	let chunkEnd = Math.min(start + chunkSize, csv.size);
	// 	const chunk = csv.slice(start, chunkEnd);
	// 	const chunkForm = new FormData();
	// 	chunkForm.append('start', start);
	// 	chunkForm.append('end', chunkEnd);
	// 	chunkForm.append('file', chunk, "chunkTestCSV");
	//
	// 	let blobEnd = chunkEnd - 1;
	// 	let contentRange = "bytes " + start + "-" + blobEnd + "/" + csv.size;
	// 	uploadChunk(chunkForm, contentRange);
	// }
	// const uploadChunk = (chunk, contentRange) => {
	// 	let url = 'https://model-performance-api.ml-production.skenarios.dev/'
	// 	axios.post(url, chunk, {
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 			'Content-Range': contentRange
	// 		}
	// 	})
	// 		.then(function (response) {
	// 			console.log(response)
	// 			setTicking(true)
	// 			setActiveStep(2)
	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		});
	// }

	const exportToXLSX = () => {
		let name = new Date().toDateString();
		let new_worksheet = XLSX.utils.json_to_sheet(data);
		let new_workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(new_workbook, new_worksheet, "xlsx")
		XLSX.writeFile(new_workbook, name + ".xlsx")
	}

	useEffect(() => {
		if (file) {
			handleFile()
		}
	}, [file, handleFile])


	const resetState = () => {
		setActiveStep(0)
		setData([])
		setFile("")
		setCols([])
		setTicking(false)
		setProgress(10)
		setError(false)
		setCSV("")
		setDataForCSV([])
		setBtnLoading(false)
		setMissingHeaders([])
		setSESSION_ID(new Date().toISOString() + ":" + uuidv4())
	}


	const getComponent = () => {
		switch (activeStep) {
			case 0:
				return <> <FileUploader
					missingHeaders={missingHeaders}
					handleChange={handleChange}
					handleFile={handleFile}/>
					{getModelVersionDays()?.modelVersion && `Model version: ${getModelVersionDays()?.modelVersion}     (${getModelVersionDays()?.daysDifference}) days since last update`}</>;

			case 1:
				return <PreviewTable
					errorMessage={errorMessage}
					dataLength={dataForCSV.length}
					error={error}
					resetForm={resetState}
					data={data}
					cols={cols}
					file={file}
					isBtnLoading={btnLoading}
					handleClick={() => {
						setBtnLoading(true)
						sendFile()
					}}/>

			case 2:
				return <ProgressView
					value={progress}
					error={error}
					resetForm={resetState}
					tryAgain={() => {
						setError(false)
					}
					}/>

			case 3:
				return <PreviewTable
					errorMessage={errorMessage}
					dataLength={data.length}
					error={error}
					data={data}
					cols={cols}
					resetForm={resetState}
					handleClick={() => setActiveStep(4)}
					type={"result"}
					getReport={() => {
						getReport()
						setIsModalOpen(true)
					}
					}
					exportToXLSX={exportToXLSX}/>

			case 4:
				return <div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						marginTop: "100px"
					}}>
					<div style={{padding: "50px", marginTop: "50px"}}>
						<Alert variant="filled" severity="error"
							   sx={{marginBottom: "25px"}}>Something went wrong while uploading!</Alert>
						<Button variant="outlined" onClick={() => resetState()} sx={{marginRight: "5px"}}
						>Try again with new session!</Button>
					</div>
				</div>

			default:
				return <h1>Error something wrong!</h1>
		}
	}

	const changeLanguage = (language) => {
		localStorage.setItem('language', language);
		setLanguage(language)
		setUserLanguage(language)
	}

	const getReport = (val) => {
		let url = 'https://model-performance-api.ml-production.skenarios.dev/report'
		setModalError(false);
		axios(url, {
			method: 'GET',
			headers: {
				'allow': 'e=mc^2',
				'session': SESSION_ID
			},//Force to receive data in a Blob Format , it must be changed if pdf
		}).then(function (response) {

			//If Pdf needs change of the blob
			// const file = new Blob(
			// 	[response.data],
			// 	{type: 'application/pdf'});
			// //Build a URL from the file
			// const fileURL = URL.createObjectURL(file);
			//Open the URL on new Window , do not delete might need later.
			// 			window.open(fileURL);
			setReportPDF(response.data)
		})
			.catch(function (error) {
				setModalError(true);
				console.log(error);

			});
	}
	const getVersion = (val) => {
		let url = 'https://model-performance-api.ml-production.skenarios.dev/ver'
		setModalError(false);
		axios(url, {
			method: 'GET',
			headers: {
				'allow': 'e=mc^2',
			},
		}).then((res) => {

			if (res.data.length) {
				setModelVersion(res.data[0])
			}
		})
			.catch((error) => {
				setModalError(true);
				console.log(error);

			});
	}

	useEffect(() => {
		getVersion();
	}, [])


	return (
		<div className="App">
			<div style={{float: "right", padding: "20px 40px 0px 0px", position: "fixed", right: 0}}>
				<Stack direction="row" spacing={1}>
					<Avatar alt="English" src={englishFlag} sx={{
						width: 24,
						height: 24,
						cursor: "Pointer",
						border: "3px solid",
						borderColor: userLanguage === "EN" ? "#113c70" : "white"
					}}
							onClick={() => changeLanguage("EN")}/>
					<Avatar alt="Finnish" src={finnishFlag} sx={{
						width: 24,
						height: 24,
						cursor: "Pointer",
						border: "3px solid",
						borderColor: userLanguage === "FI" ? "#113c70" : "white"
					}}
							onClick={() => changeLanguage("FI")}/>
				</Stack>
			</div>
			<Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justifyContent="center"
				style={{minHeight: '100vh', width: "100%"}}
			>
				<Grid item padding={0} style={{width: "95%", margin: "0 auto"}}>
					<img src={mainLogo} alt={"main-logo"} style={{width: "30%", marginBottom: "25px"}}/>
					<Paper elevation={3} style={{padding: "50px", minHeight: "650px"}}>
						<div style={{width: "50%", margin: "0 auto"}}>
							<Stepper activeStep={activeStep}>
								{steps.map((label, index) => (
									<Step key={label}
									>
										<StepLabel
										>
											{l(label)}
										</StepLabel>
									</Step>
								))}
							</Stepper>
						</div>
						{getComponent()}

					</Paper>
				</Grid>
			</Grid>
			<ReportPDFpreview getReport={getReport} modalError={modalError} isModalOpen={isModalOpen}
							  handleClose={() => setIsModalOpen(false)}
							  reportPDF={reportPDF}/>
		</div>
	);
}

export default App;
