import React, {useRef} from "react";
import {Alert, Box, Button, CircularProgress, IconButton, Modal} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {getLocalizer} from "../scripts/languageSettings";
import RefreshIcon from "@mui/icons-material/Refresh";
import ReactToPrint from "react-to-print";
import PrintComponent from "./printComponent";
import PrintIcon from '@mui/icons-material/Print';

const l = getLocalizer();
const ReportPDFpreview = (props) => {
	const componentRef = useRef();
	const style = {
		position: 'absolute',
		height: "90%",
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: "90%",
		bgcolor: 'background.paper',
		border: '1px solid #ccc',
		boxShadow: 5,
		padding: "50px",
		p: 4,
	}
	return (
		<Modal
			open={props.isModalOpen}
			onClose={props.handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<div style={{display: "flex", justifyContent: "space-between", padding: "5px"}}>
					<div>
						<ReactToPrint
							trigger={() => <PrintIcon sx={{fontSize: '30px', cursor: "Pointer"}}/>}
							content={() => componentRef.current}
						/>
						<div style={{display: "none", visibility: "hidden"}}><PrintComponent reportPDF={props.reportPDF}
						                                                                     componentRef={componentRef}/>
						</div>
					</div>
					<h1 style={{fontSize: "25px"}}>
						{l("report_preview")}
					</h1>
					<IconButton aria-label="upload picture" component="span" onClick={props.handleClose}
					            sx={{float: "right",}}>
						<CloseIcon sx={{fontSize: '30px', cursor: "Pointer"}}/>
					</IconButton>
				</div>
				<div style={{
					display: "flex",
					justifyContent: "flex-start",
					alignItems: "flex-start",
					height: "100%",
				}}>

					{props.reportPDF ?
						<iframe title={"report"} style={{width: "100%", height: "90%", padding: "10px", border: "none"}}
						        srcDoc={props.reportPDF}></iframe>
						: !props.modalError && <CircularProgress sx={{marginTop: "-100px"}}/>}
					{props.modalError && <div style={{padding: "50px", width: "40%"}}>
						<Alert variant="filled" severity="error"
						       sx={{marginBottom: "25px"}}>{l("error")}</Alert>
						<div style={{display: 'flex', justifyContent: "space-around"}}>
							<Button variant={"outlined"}
							        onClick={props.getReport}
							        startIcon={<RefreshIcon/>}>{l("try_again_btn")} </Button>
							<Button onClick={props.handleClose} variant={"outlined"}
							        startIcon={<CloseIcon/>}>{l("close")} </Button>
						</div>
					</div>}
				</div>

			</Box>
		</Modal>
	)
}

export default ReportPDFpreview